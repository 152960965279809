import "../../components/blocks/header/header";
import "../../components/blocks/footer/footer";
import "../../components/blocks/brand/brand";
import "../../components/blocks/brand-content/brand-content";
import "../../components/blocks/preloader/preloader";
import "../../components/blocks/preloader/preloader-animation";
import "../../components/blocks/football-field/football-field";
import "../../components/blocks/full-page-overlay/full-page-overlay";
import "../../components/blocks/button-band/button-band";
import "../../components/blocks/types-sports/types-sports";
import "../../components/blocks/childhood/childhood";
import "../../components/blocks/equipment/equipment";
import "../../components/blocks/core/core";
import "../../components/blocks/advantage/advantage";
import "../../components/blocks/header/header-color";
import "../../components/blocks/menu/menu";
import "../../components/blocks/partners/partners";
import "../../components/blocks/color-picker/color-picker";
import "../../components/blocks/sizes/sizes";
import "../../components/sections/detailed/detailed";
import "../../components/sections/carousel/carousel";
import "../../components/modals/modal-table/modal-table";
import "../../components/blocks/about-brand/about-brand";
import "../../components/blocks/header/header-color--white-all";
import "../../components/blocks/progress/progress";
import "../../components/blocks/countries/countries";
import "../../components/blocks/services/services";

import "../components/AnchorScroll";
import "../components/ScrollTitleAppear";
import "../components/ScrollTitleVerticalAppear";
import "../components/Accordion";
