import "../../components/ui-kit/dropdown/dropdown";
import "../../components/ui-kit/accordion/accordion";
import "../../components/ui-kit/scrollbar/scrollbar";
import "../../components/ui-kit/button--static/button--static";
import "../../components/ui-kit/input-block/input-block";

function bodyNoScroll() {
  let bodyBodymotionless = document.querySelector("body");
  bodyBodymotionless.classList.add("Bodymotionless");
}
function bodyYesScroll() {
  let bodyBodymotionless = document.querySelector("body");
  bodyBodymotionless.classList.remove("Bodymotionless");
}
