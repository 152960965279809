import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);

class AnchorScroll {
  constructor() {
    this.links = document.querySelectorAll('[data-anchor-link]');
    this.links.forEach((link) => {
      link.addEventListener('click', (e) => {
        e.preventDefault();
        const blockId = link.hash;
        const destination = document.querySelector(blockId);
        if (destination && window.innerWidth > 1024) {
          gsap.to(window, {
            scrollTo: { y: destination},
          });
        } else if (destination && window.innerWidth <= 1024) {
          gsap.to(window, {
            scrollTo: { y: destination, offsetY: 50},
          });
        }
      });
    });
  }
}

export default new AnchorScroll();
