import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import childhoodAppear from "../childhood/childhood"
import headerAppear from "../header/header"
import { disablePageScroll, enablePageScroll } from "scroll-lock";
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);

class footballFieldAppear {
  constructor() {
		this.index = 0;
		var index = this.index;
    this.container = document.querySelector('[data-footballField]');
    this.picture = document.querySelector('.football-field__picture');
    // this.graffiti = document.querySelector('.football-field__graffiti');
    this.radiusOne = document.querySelector('.football-field__radius--picture');
    this.radiusTwo = document.querySelector('.football-field__radius--two');
    this.childhoodSection = document.querySelector('.childhood__section--no-scrolled');
		
		this.timeline = gsap.timeline({});
		this.timeline.set(this.container, {autoAlpha: 0, width: "100%", height: "100%", position: "absolute", paddingBottom: "1.3889vw" });
		this.timeline.set(this.picture, {autoAlpha: 0, width: "100%", height: "100%", position: "absolute", });
		this.timeline.set(this.radiusOne, {autoAlpha: 0, rotate: "-95deg", });
		this.timeline.set(this.radiusTwo, {autoAlpha: 0, scale: 1, });
		// this.timeline.set(this.graffiti, {autoAlpha: 1, opacity: 1, });

		this.timeline.pause()
 
		this.timeline.to(this.container, {
			autoAlpha: 1,
      duration: 2,
			position: "fixed",
			width: "100vw", 
			height: "100vh",
			paddingBottom: "0",
			onReverseComplete: function() {
				enablePageScroll();
			},
			onComplete: function() {
				disablePageScroll();
			}
    },"0");
		this.timeline.to(this.picture, {
			autoAlpha: 1,
      duration: 1.5,
			position: "fixed",
			width: "100vw", 
			height: "100vh",
			onReverseComplete: function() {
				enablePageScroll();
			},
			onComplete: function() {
				childhoodAppear.onStartAnimation();
			}
    },"0");
		// this.timeline.to(this.graffiti, {
		// 	autoAlpha: 0,
    //   duration: 0.2,

		// 	onComplete: function() {
		// 		childhoodAppear.onStartAnimation()
		// 	}
    // },"0");
		this.timeline.to(this.radiusOne, {
			autoAlpha: 1,
      duration: 1,
			rotate: "0deg",
			delay: 1,
			// onReverseComplete: function() {
			// 	headerAppear.changeColor("white")
			// },
			// onComplete: function() {
			// 	headerAppear.changeColor("black")
			// }
    },">");

		this.timeline.to(this.radiusTwo, {
			autoAlpha: 1,
      duration: 1,
			scale: 2.2,
			delay: 0.2,
			onReverseComplete: function() {
				headerAppear.changeColor("white")
				
			},
			onComplete: function() {
				headerAppear.changeColor("black")
			}

    },">");
		this.timeline.to(this.radiusOne, {
			autoAlpha: 0,
      duration: 0.5,
			rotate: "-45deg",

    },"<");

  }
	onStartAnimation() {
		this.index = 1;
		this.timeline.play()

	}
	onPlayAnimation() {		
		this.timeline.play()
	}
	onReverseAnimation() {
		this.index = 2;
		this.timeline.reverse()
		this.childhoodSection.classList.add("no__scroll")





	}

}

class footballFieldAppearController {
  constructor() {
		if(isMobile == false) {
      new footballFieldAppear()
    } else {
      return;
    }
		
  }
}
new footballFieldAppearController()
export default new footballFieldAppear();

