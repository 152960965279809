import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import headerAppear from "../header/header"
import footballFieldAppear from "../football-field/football-field"
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);

class childhoodAppear {
  constructor() {
    this.container = document.querySelector('.childhood__section') || null;
		if(this.container !==null) {
			this.childhood = this.container.querySelector('.childhood') || null;
			this.subtitle = this.container.querySelector('.childhood__subtitle') || null;
			this.titleHider = this.container.querySelector('.title-hider') || null;
			this.text = this.container.querySelectorAll('.childhood__text') || null;
			this.picture = this.container.querySelector('.childhood__picture__bg') || null;
			
		}

		this.timeline = gsap.timeline({});	
		this.timeline.set(this.container, {autoAlpha: 0, y: "100%" });
		this.timeline.set(this.container, {autoAlpha: 0,  });
		this.timeline.set(this.childhood, {autoAlpha: 0,  });
		this.timeline.set(this.text, {autoAlpha: 0,  });
		this.timeline.set(this.picture, {autoAlpha: 1,  top: 0, });
		this.timeline.set(this.titleHider, {autoAlpha: 1,  x: "0%" });
		this.timeline.pause()
		this.timeline.to(this.container, {
			autoAlpha: 0,
      duration: 1.5,
			y: "0%",
			delay: 1,
    },"0");
		this.timeline.to(this.container, {
			autoAlpha: 1,
      duration: 1,
    },">");
		this.timeline.to(this.childhood, {
			autoAlpha: 1,
      duration: 2,
			delay: 0.5,
			onComplete: function() {				
				enablePageScroll();
			}
    },">");
		this.timeline.to(this.picture, {
			autoAlpha: 1,
			top: "110%",
      duration: 1,
			delay: 0,
			onReverseComplete: function() {
				disablePageScroll();
			},
			onComplete: function() {				
				enablePageScroll();
			}
    },"<");
		this.timeline.to(this.titleHider, {
			x: "200%",
      duration: 2,
			delay: 0,
    },"<");
		this.timeline.to(this.text, {
			autoAlpha: 1,
      duration: 1,
			delay: 0,
    },"<");

  }
	onStartAnimation() {
		this.timeline.play();
	}
	onReverseAnimation() {
		// disablePageScroll();
		this.timeline.reverse()
		setTimeout(function() {
			footballFieldAppear.onReverseAnimation()	
		}, 2400)
			

	}

}

class childhoodAppearController {
  constructor() {
		if(isMobile == false) {
      new childhoodAppear()
    } else {
      return;
    }
		
  }
}
new childhoodAppearController()
export default new childhoodAppear();

