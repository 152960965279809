import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
const defaultScrollTrigger = {
  start: 'top 65%',
  end: 'top 90%',
  toggleActions: 'play none none reverse',
};

class ScrollTitleAppear {
  constructor(container) {
    this.container = container;

    this.title = this.container.querySelector('[data-scroll-title]');

    gsap.set(this.title, {autoAlpha: 1,  top: "120%", });
    
    gsap.to(this.title, {
      scrollTrigger: {
        ...defaultScrollTrigger,
        trigger: this.container,
      },
			autoAlpha: 1,
			top: "0%",
      duration: 1.2,
    });

    if (this.title) {
      gsap.to(this.title, {
        scrollTrigger: {
          ...defaultScrollTrigger,
          trigger: this.container,
        },
        autoAlpha: 1,
        duration: 1.2,

      });
    }
  }
}

class ScrollTitleAppearController {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      document.querySelectorAll('[data-scroll-title-appear]')?.forEach((container) => {
        this.instances.push(new ScrollTitleAppear(container));
      });
    } else {
      return;
    }

  }
}

export default new ScrollTitleAppearController();
