import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
const coreScrollTrigger = {
  start: 'top 65%',
  end: 'top 10%',
  toggleActions: 'play none none reverse',
};
class coreAppear {
  constructor(container) {
    this.container = container;
		this.graffiti = this.container.querySelector('.core__graffiti') || null;
		this.picture = this.container.querySelector('.core__picture__bg') || null;
		

		gsap.set(this.picture, {autoAlpha: 1,  top: 0, });
		gsap.set(this.graffiti, {autoAlpha: 0,  });


    gsap.to(this.picture, {
      scrollTrigger: {
        ...coreScrollTrigger,
        trigger: this.container,
      },
			autoAlpha: 1,
			top: "110%",
      duration: 1.2,
    });

    if (this.picture) {
      gsap.to(this.picture, {
        scrollTrigger: {
          ...coreScrollTrigger,
          trigger: this.container,
        },
        autoAlpha: 1,
        duration: 1.2,

      });
    }
    gsap.to(this.graffiti, {
      scrollTrigger: {
        ...coreScrollTrigger,
        trigger: this.container,
      },
			autoAlpha: 1,
      duration: 1.2,
			delay: 1,
    });

    if (this.graffiti) {
      gsap.to(this.graffiti, {
        scrollTrigger: {
          ...coreScrollTrigger,
          trigger: this.container,
        },
        autoAlpha: 1,
        duration: 1.2,

      });
    }
  }
}

class coreAppearStart {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      document.querySelectorAll('[data-core-item-animation]')?.forEach((container) => {
        this.instances.push(new coreAppear(container));
      });
    } else {
      return;
    }
  }
}

export default new coreAppearStart();


