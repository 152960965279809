const searchBlocks = document.querySelectorAll("[data-search-block]");
if (searchBlocks.length) {
  searchBlocks.forEach((block) => {
    const input = block.querySelector("input[type=text]");
    const clearButton = block.querySelector("[data-clear-button]");
    clearButton.addEventListener("click", () => {
      input.value = "";
    });
  });
}
