import utils from '../../../js/utils/utils';

class HeaderScroll {
  constructor() {
    this.header = document.querySelector('[data-header-scroll-change]');
    if (!this.header) return;

    window.addEventListener(
      'scroll',
      utils.throttle(() => this.checkScrollHeader(), 200)
    );
  }

  checkScrollHeader() {
    // console.log(window.scrollY);
    if (window.scrollY > this.header.offsetHeight + 300) {
			this.header.classList.remove("header--gray", "header--black", "header--transparent" );
      this.header.classList.add('header--white');
      this.header.style.opacity = 1;
    } else {
      this.header.classList.remove("header--white", "header--gray", "header--black", "header--transparent" );
			this.header.classList.add("header--transparent");    
		}
  }
}

export default new HeaderScroll();