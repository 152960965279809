import Swiper from "swiper";
import { Navigation } from "swiper/modules";

window.addEventListener("load", initSlider);

function initSlider() {
  const carouselSections = document.querySelectorAll(".carousel");
  carouselSections.forEach((carousel) => {
    const sliders = carousel.querySelectorAll("[data-carousel-slider]");

    if (sliders.length) {
      sliders.forEach((slider) => {
        if (slider.swiper) return false;

        const prevBtn = carousel.querySelector("[data-button-prev]") || null;
        const nextBtn = carousel.querySelector("[data-button-next]") || null;

        const carouselSlider = new Swiper(slider, {
          modules: [Navigation],
          slidesPerView: 4,
          speed: 600,
          allowTouchMove: true,
          navigation: {
            nextEl: nextBtn,
            prevEl: prevBtn,
          },
          breakpoints: {
            1025: {
              freeMode: false,
              spaceBetween: 20,
            },
            319: {
              spaceBetween: 5,
              slidesPerView: 2.6,
              freeMode: true,
              freeModeMomentum: false,
            },
          },
        });
      });
    }
  });
}
