import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, } from 'swiper/modules';

const isMobile = window.matchMedia('(max-width: 1024px)');

window.addEventListener('load', initSlider);
function randomNumber(min, max) {
	return Math.random() * (max - min) + min;
}
function initSlider() {
  const sliders = document.querySelectorAll('[data-partners-slider]');

  sliders.forEach((slider) => {
    if (slider.swiper) return false;

		let speed  = randomNumber(1000, 9000);
		const miniSlider = new Swiper(slider, {
			modules: [Navigation, Pagination, Autoplay,],
			slidesPerView: "auto",
			speed: speed,
			freeMode: true,
			freeModeMomentum: false,
			freeModeMomentumBounce: false,
			direction: "vertical",
			spaceBetween: 40,
			loop: false,

			// slidesPerGroup: 1,
			autoplay: {
				delay: 0,
				// reverseDirection: true,
				disableOnInteraction: true
			},
			followFinger: isMobile.matches,
			shortSwipes: isMobile.matches,
			longSwipes: isMobile.matches,
			allowTouchMove: isMobile.matches,
			resizeObserver: true,
			watchOverflow: true,

		});
  });
}
