/* eslint-disable linebreak-style */
/* eslint-disable no-undef */
/* eslint-disable quotes */
/* eslint-disable linebreak-style */

class Project {
  constructor() {
    this.container = document.getElementById("modal-project");
    if (!this.container || this.container.project) return;
    this.container.project = this;
    this.url = this.container.dataset.projectUrl;
    this.initTargetButtons();
  }

  initTargetButtons() {
    document.querySelectorAll('[data-modal-target="#modal-project"]').forEach((btn) => {
      btn.addEventListener("click", async () => {
        const id = btn.closest("[data-project-id]").dataset.projectId;
        await this.setData(id);
      });
    });
  }

  async setData(id) {
    window.showLoader("loader1");
    const data = await this.getData(id);
    window.hideLoader("loader1");

    const modal = document.getElementById("modal-error");
    this.contentBlock = document.querySelector("[data-modal].active [data-project-content]");

    if (data && data.data) {
      this.data = data.data;
      if (this.data.html) {
        this.contentBlock.innerHTML = this.data.html;
        this.reinitFormComponents();
      }
      if (!data.data.success && data.data.error && modal) {
        this.container.modal.close();
        modal.querySelector("[data-modal-error-text]").innerHTML = data.data.error;
        modal.classList.add("_simple");
        modal.modal.open();
      }
    } else {
      this.container.modal.close();
      if (modal) {
        modal.querySelector("[data-modal-error-text]").innerHTML = "";
        modal.classList.add("_simple");
        modal.modal.open();
      }
    }
  }

  async getData(id) {
    const url = `${this.url}&projectId=${id}`;

    try {
      if (!url) {
        throw new Error("url для просмотра детального счета не найден");
      }
      const request = await fetch(url, {
        method: "GET",

        headers: { "Content-Type": "application/json" },
      });
      return await request.json();
    } catch (error) {
      console.error(error);
    }
  }

  reinitFormComponents() {
    window.Modal.initAll();
    window.initSliderDefault();
  }
}

export default new Project();
