import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import brandAppearController from "../brand/brand"
import headerAppear from "../header/header"
import { disablePageScroll, enablePageScroll } from "scroll-lock";
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);

class preloaderAppear {
  constructor(container) {
    this.container = container;
		this.preloaderBG = this.container.querySelectorAll('.preloader__bg');
		this.preloaderSymbolStart = this.container.querySelectorAll('.preloader__text__symbol._start');
		this.preloaderSymbolHide = this.container.querySelectorAll('.preloader__text__symbol._hide');
		let tl1 = gsap.timeline({});
    disablePageScroll();
		tl1.set(this.container, {autoAlpha: 1, y: '0%',});

		tl1.set(this.preloaderBG, {autoAlpha: 1, y: '0%',});

		tl1.set(this.preloaderSymbolStart[0], {autoAlpha: 1, x: '126%',});
		tl1.set(this.preloaderSymbolStart[1], {autoAlpha: 1, x: '240%',});
		tl1.set(this.preloaderSymbolStart[2], {autoAlpha: 1, x: '197%',});
		tl1.set(this.preloaderSymbolStart[3], {autoAlpha: 1, x: '90%',});
		tl1.set(this.preloaderSymbolStart[4], {autoAlpha: 1, x: '-246%',});

		tl1.set(this.preloaderSymbolHide[0], {autoAlpha: 0, x: '200%',});
		tl1.set(this.preloaderSymbolHide[1], {autoAlpha: 0, x: '350%',});
		tl1.set(this.preloaderSymbolHide[2], {autoAlpha: 0, x: '-30%',});
		tl1.set(this.preloaderSymbolHide[3], {autoAlpha: 0, x: '-150%',});
		tl1.set(this.preloaderSymbolHide[4], {autoAlpha: 0, x: '-210%',});



    tl1.to(this.preloaderSymbolStart[0], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolStart[0], {
      x: '126%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolStart[1], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolStart[1], {
      x: '240%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolStart[2], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolStart[2], {
      x: '197%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolStart[3], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolStart[3], {
      x: '90%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolStart[4], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolStart[4], {
      x: '-246%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolHide[0], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolHide[0], {
      x: '200%',
			autoAlpha: 0,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolHide[1], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolHide[1], {
      x: '350%',
			autoAlpha: 0,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolHide[2], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolHide[2], {
      x: '-30%',
			autoAlpha: 0,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolHide[3], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolHide[3], {
      x: '-150%',
			autoAlpha: 0,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderSymbolHide[4], {
      x: '0%',
			autoAlpha: 1,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.preloaderSymbolHide[4], {
      x: '-210%',
			autoAlpha: 0,
      duration: 1.5,
			delay: 0.5,
    },">");
    tl1.to(this.preloaderBG, {
			autoAlpha: 0.8,
      duration: 1.5,
			delay: 0.5,
    },"0");
    tl1.to(this.container, {
			y: "-100%",
			autoAlpha: 0.8,
      duration: 1,
			delay: 2,
      onComplete: function() {
        brandAppearController.onStart()
        headerAppear.onStartAnimation()
        headerAppear.changeColor("white")
        enablePageScroll()
      },
    },">");




  }
}

class preloaderAppearController {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      let dataPreloader = document.querySelectorAll('[data-preloader]')
			if(dataPreloader.length) {
        dataPreloader.forEach((container) => {
        this.instances.push(new preloaderAppear(container));
        });
      };
    } else {
      document.querySelectorAll('[data-preloader]')?.forEach((container) => {
        this.instances.push(new preloaderAppear(container));
      });
      // return;
    }
  }
}

export default new preloaderAppearController();

