import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
const cultureStartScrollTrigger = {
  start: 'top 35%',
  end: 'top 90%',
  toggleActions: 'play none none reverse',
};

class HeaderColor {
  constructor(container) {
    this.container = container;
		this.header = document.querySelector('[data-header]');
		this.sectionColor = this.container
		gsap.to(this.container, {
      scrollTrigger: {
        ...cultureStartScrollTrigger,
        trigger: this.container,
      },
			// onReverseComplete: function() {
			// 	let color = this.sectionColor
			// 	console.log(color)
			// },
			// onComplete: function() {
			// 	let color = this.sectionColor
			// 	console.log(color)
			// }
    });

    if (this.container) {
      gsap.to(this.container, {
        scrollTrigger: {
          ...cultureStartScrollTrigger,
          trigger: this.container,
        },


      });
    }

  }
	changeColor(color) {
		let container = this.header
		let key = color
		function delClass() {
			container.classList.remove("header--white", "header--gray", "header--black", "header--transparent" )
		}
		switch(key) {
			case "gray":
				delClass();
				container.classList.add("header--gray")	
				break;
			case "white":
				delClass();
				container.classList.add("header--white")	
				break;
			case "transparent":
				delClass();
				container.classList.add("header--transparent")	
				break;
			case "black":
				delClass();
				container.classList.add("header--black")	
				break;
		}
	}
}

class HeaderColorController {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
			let dataSectionColor = document.querySelectorAll('[data-sectionColor]')
			if(dataSectionColor.length) {
				dataSectionColor.forEach((container) => {
					this.instances.push(new HeaderColor(container));
				});
			}

    } else {
      return;
    }
  }
}

export default new HeaderColorController();