let advantegeList = document.querySelectorAll("[data-advantage-list-min-height]") || null

function assignHeight() {
	advantegeList.forEach(el => {
		let allHeight = [];
		let advantegeItemText = el.querySelectorAll(".advantege__item__text");
		advantegeItemText.forEach ( item => {
			allHeight.push(item.offsetHeight);
		})
		let maxHeight = allHeight.sort((a,b) => b - a) [0];
		advantegeItemText.forEach ( item => {
			item.style.height = maxHeight + "px"
		})
	});
}
if(advantegeList) {
	assignHeight();
	window.addEventListener('resize', function() {
		assignHeight();
	});
}
