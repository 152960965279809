import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import footballFieldAppear from "../football-field/football-field"
import headerAppear from "../header/header"
import childhoodAppear from "../childhood/childhood"
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(MotionPathPlugin);
gsap.registerPlugin(ScrollToPlugin);

const scrollPage = document.querySelectorAll(".scroll-page")

let tl = gsap.timeline({
	// onComplete: function (){
	// }
});
var initMode;
let addTime = 100;
let addTimeTwo = 100;

let st1, st2, st3;
let tl1 = gsap.timeline({});
let tl2 = gsap.timeline({});
let tl3 = gsap.timeline({});

let s3Width, b1Width, b2Width, b3Width, b4Width;
let s3Height, b1Height, b2Height, b3Height, b4Height;


let urlParams = new URLSearchParams(window.location.search);
let yValue = urlParams.get('y');

function initAnimation(){
	if (window.innerWidth > 1024){
		initScrollAnimationDesktop();
		initMode = 'desk';
} else if(window.innerWidth > 699){
		// initScrollAnimationTablet();
		initMode = 'tablet';
} else {
		// initScrollAnimationMobile();
		initMode = 'mobile';
}
}
if(scrollPage.length) {
	initAnimation();
}



let dwidth = window.innerWidth;


function tlStart() {
	tl1.clear();

}
function initScrollAnimationDesktop(){
	window.addEventListener('resize', () => {
		const wwidth = window.innerWidth;
		if(dwidth!==wwidth){
			if (typeof st1 !== "undefined") st1.kill();
			if (typeof st2 !== "undefined") st2.kill();
			if (typeof st3 !== "undefined") st3.kill();
			tl.clear();
			tl1.clear();
			tl2.clear();
			tl3.clear();
			// gsap.set("[data-brand-content]," +
			// 	".equipment," +
			// 		".scroll-page, ",  {clearProps:"all"});
			window.scrollTo({
					top: 0,
					behavior: "instant"
			});
			initAnimation();
	}
	});
	
	tl1.fromTo("[data-brand-content]", {
		y: '20vh',
	}, {
			y: '-20vh',
			duration:  2,
			ease: "none",
			onComplete: function() {
				footballFieldAppear.onStartAnimation()
				headerAppear.changeColor("gray")
				disablePageScroll();
			},
	} , "0");
	tl1.fromTo(".equipment", {
		y: '0vh',
	}, {
			y: '0vh',
			duration:  1,
			ease: "none",
			onComplete: function() {
			},
			onReverseComplete: function() {
				// disablePageScroll();
				tlStart()
				childhoodAppear.onReverseAnimation()
			}

	} , ">+=2.5");
	st1 = ScrollTrigger.create({
		trigger: ".scroll-page",
		pin: true,
		start: "top top",
		end: () =>   "+="+addTime+"%",
		scrub: 2.5,
		animation: tl1,
});



}