export default class SizePicker {
  constructor(el) {
    this.defaults = defaults;
    this.el = el;
    this.sizeButtons = [];
    this.activeBtn = null;
    this.currentValue = null;
    this.modal = document.querySelector(this.defaults.selectors.modal);
    this.isController = this.el.getAttribute("data-size-picker-controller");
    this.sizes = [];
    this.boundedBasketButton = document.querySelector(`[data-button-counter=${this.el.getAttribute(["data-sizes-picker"])}`);

    this.init();
  }

  init() {
    this.updateSizeButtons();
    this.applyBtnsClick();
    this.el.SizePicker = this;
  }

  applyBtnsClick() {
    this.sizeButtons.forEach((btn, idx) => {
      this.sizes.push(btn.getAttribute("data-size"));
      btn.addEventListener("click", () => {
        if (this.currentValue !== btn.getAttribute("data-size")) {
          this.handleClick(btn);

          if (this.isController) {
            this.boundedSizePicker = document.querySelector(`[data-sizes-picker=${this.el.getAttribute("data-bounded-sizepicker")}]`);
            this.handleBoundedClick(idx);
          }
        }
      });
    });
    this.sizes = [...new Set(this.sizes)];
  }

  handleClick(btn) {
    this.unActivateBtn();
    this.activateBtn(btn);
  }

  handleBoundedClick(idx) {
    this.boundedSizePicker.SizePicker.handleClick(this.boundedSizePicker.SizePicker.sizeButtons[idx]);
  }

  activateBtn(btn) {
    this.resetBoundedButton();
    btn.classList.add("active");
    this.activeBtn = btn;
    this.currentValue = btn.getAttribute("data-size");

    if (this.modal.modal._isOpen) {
      this.modal.modal.close();
    }
  }

  unActivateBtn() {
    this.activeBtn?.classList.remove("active");
  }

  resetBoundedButton() {
    if (this.boundedBasketButton !== null) {
      this.boundedBasketButton.classList.remove("active");
      this.boundedBasketButton.removeAttribute("data-good-added");
      this.boundedBasketButton.querySelector("[data-count]").value = 1;
    }
  }

  drawNewButtons(sizesArr) {
    this.el.innerHTML = "";
    sizesArr.forEach((size) => {
      const node = document.createElement("div");
      node.classList = "sizes__item";
      node.setAttribute("data-size", size);
      node.innerHTML = size;
      this.el.appendChild(node);
    });
    this.updateSizeButtons();
    this.applyBtnsClick();
  }

  updateSizeButtons() {
    this.sizeButtons = this.el.querySelectorAll(this.defaults.selectors.sizeButton);
  }

  static initAll() {
    const els = document.querySelectorAll(defaults.selectors.el);
    return [...els].map((el) => new SizePicker(el));
  }
}

const defaults = {
  selectors: {
    el: "[data-sizes-picker]", // READONLY
    sizeButton: "[data-size]",
    modal: "#modal-size",
  },
};

SizePicker.defaults = defaults;

window.SizePicker = SizePicker;

SizePicker.initAll();
