import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
const equipmentScrollTrigger = {
  start: 'top 75%',
  end: 'top 10%',
  toggleActions: 'play none none reverse',
};
class equipmentAppear {
  constructor(container) {
    this.container = container;
		this.equipment = this.container.querySelector('.equipment') || null;
		this.subtitle = this.container.querySelector('.equipment__subtitle') || null;
		this.title = this.container.querySelector('.equipment__title') || null;
		this.picture = this.container.querySelector('.equipment__picture__bg') || null;
		

		gsap.set(this.picture, {autoAlpha: 1,  top: 0, });


    gsap.to(this.picture, {
      scrollTrigger: {
        ...equipmentScrollTrigger,
        trigger: this.picture,
      },
			autoAlpha: 1,
			top: "110%",
      duration: 1,
			delay: 0.5,
    });

    if (this.title) {
      gsap.to(this.picture, {
        scrollTrigger: {
          ...equipmentScrollTrigger,
          trigger: this.container,
        },
        autoAlpha: 1,
        duration: 1.5,

      });
    }
  }
}

class equipmentAppearStart {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      document.querySelectorAll('.equipment__section')?.forEach((container) => {
        this.instances.push(new equipmentAppear(container));
      });
    } else {
      return;
    }
  }
}

export default new equipmentAppearStart();

