import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';

const isMobile = window.matchMedia('(max-width: 1024px)');

window.addEventListener('load', initSlider);

function initSlider() {
  const sliders = document.querySelectorAll('[data-button-band]');

  sliders.forEach((slider) => {
    if (slider.swiper) return false;

    const prevBtn = slider.querySelector('.button-icon--prev') || null;
    const nextBtn = slider.querySelector('.button-icon--next') || null;
    const pagination = slider.querySelector('[data-slider-pagination]') || null;
    let activeIndex;
		const miniSlider = new Swiper(slider, {
			modules: [Navigation, Pagination, Autoplay,],
			slidesPerView: "auto",
			speed: 5000,
			freeMode: true,
			freeModeMomentum: false,
			loop: true,
			slidesPerGroup: 1,
			autoplay: {
				delay: 0,
				// disableOnInteraction: true
			},
			followFinger: isMobile.matches,
			shortSwipes: isMobile.matches,
			longSwipes: isMobile.matches,
			allowTouchMove: isMobile.matches,
			resizeObserver: true,
			watchOverflow: true,

			navigation: {
				prevEl: prevBtn,
				nextEl: nextBtn,
			},

			pagination: {
				el: pagination,
				clickable: true,
			},
		});
  });
}
