import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import brandAppearController from "../brand/brand"
import headerAppear from "../header/header"
import { disablePageScroll, enablePageScroll } from "scroll-lock";
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);

class aboutBrandSectionAppear {
  constructor(container) {
    this.container = container;
		this.aboutBrandSectionText= this.container.querySelectorAll('.services__text');
		this.aboutBrandSectionLinks= this.container.querySelectorAll('.services__links');
		let tl1 = gsap.timeline({});

		tl1.set(this.aboutBrandSectionText, {autoAlpha: 1, y: '-105%',});
		tl1.set(this.aboutBrandSectionLinks, {autoAlpha: 1, y: '200%',});

    tl1.to(this.aboutBrandSectionText, {
			y: '0%',
			autoAlpha: 1,
      duration: 1.5,

    },"0");
    tl1.to(this.aboutBrandSectionLinks, {
			autoAlpha: 1,
			y: 0,
      duration: 1.5,
    },"0");


  }
}

class aboutBrandSectionAppearController {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      let el = document.querySelectorAll('[data-services-section]')
			if(el.length) {
        el.forEach((container) => {
        	this.instances.push(new aboutBrandSectionAppear(container));
        });
      };
    } else {
      return;
    }
  }
}

export default new aboutBrandSectionAppearController();

