import Swiper from "swiper";
import { Pagination } from "swiper/modules";

const isMobile = window.matchMedia("(max-width: 1024px)").matches;

window.addEventListener("load", initSlider);

function initSlider() {
  const sliders = document.querySelectorAll("[data-detailed-slider]");

  if (sliders.length) {
    sliders.forEach((slider) => {
      if (slider.swiper) return false;

      const pagination = slider.querySelector("[data-pagination]") || null;
      const detailedSlider = new Swiper(slider, {
        modules: [Pagination],
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 600,
        allowTouchMove: true,
        pagination: {
          el: pagination,
          clickable: true,
          bulletClass: "detailed__bullet",
          bulletActiveClass: "active",
        },
        init: false,
        breakpoints: {
          1025: {
            enabled: false,
          },
          319: {
            enabled: true,
          },
        },
      });
      if (isMobile) {
        detailedSlider.init();
      }
    });
  }
}
