import utils from "../../../js/utils/utils";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia("(max-width: 1024px)").matches;
window.addEventListener(
  "resize",
  function () {
    isMobile = window.matchMedia("(max-width: 1024px)").matches;
  },
  true
);
gsap.config({ nullTargetWarn: false });
const LicensesQuotetScrollTrigger = {
  start: "top 50%",
  end: "top 90%",
  toggleActions: "play none none reverse",
};

class headerAppear {
  constructor() {
    this.container = document.querySelector("[data-header]");
    this.timeline = gsap.timeline({});
  }
  onStartAnimation() {
    this.timeline.set(this.container, { autoAlpha: 0 });

    this.timeline.to(
      this.container,
      {
        autoAlpha: 1,
        duration: 1.8,
        // delay: 0.5,
      },
      "0"
    );
  }
  changeColor(color) {
    let container = this.container;
    let key = color;
    function delClass() {
      container.classList.remove("header--white", "header--gray", "header--black");
    }
    switch (key) {
      case "gray":
        delClass();
        container.classList.add("header--gray");
        break;
      case "white":
        delClass();
        container.classList.add("header--white");
        break;
      case "black":
        delClass();
        container.classList.add("header--black");
        break;
    }
  }
}

class headerAppearController {
  constructor() {
    if (isMobile == false) {
      new headerAppear();
    } else {
      return;
    }
  }
}
new headerAppearController();
export default new headerAppear();
