const imageSwitchers = document.querySelectorAll("[data-color-image-switcher]");
if (imageSwitchers.length) {
  imageSwitchers.forEach((switcher) => {
    const images = switcher.querySelectorAll("[data-image]");
    const colorPicker = switcher.querySelector(`[data-color-picker=${getMediaDevice()}]`);
    const colorButtons = colorPicker.querySelectorAll("[data-color-box]");
    const defaultActiveId = switcher.getAttribute(["data-color-image-switcher"]);

    changeActiveImage(defaultActiveId);

    let activeId = defaultActiveId;

    const boundedButton = document.querySelector(`[data-button-counter=${colorPicker.getAttribute("data-bounded-button")}]`);
    colorButtons.forEach((button, idx) => {
      button.addEventListener("click", () => {
        changeActiveImage(idx);
        resetBoundedButton(boundedButton);
      });
    });

    function changeActiveImage(elementId) {
      if (activeId !== elementId) {
        images[activeId]?.classList.remove("active");
        colorButtons[activeId]?.removeAttribute("checked");

        activeId = elementId;

        images[activeId].classList.add("active");
        colorButtons[activeId].setAttribute("checked", "");
      }
    }

    function getMediaDevice() {
      if (window.matchMedia("(max-width: 640px)").matches) {
        return "mobile";
      }
      return "desktop";
    }
  });
}

// detailed page (no images to switch)
const colorSwitchers = document.querySelectorAll("[data-color-switcher]");
if (colorSwitchers.length) {
  colorSwitchers.forEach((switcher) => {
    const colorPicker = switcher.querySelector(`[data-color-picker]`);
    const colorButtons = colorPicker.querySelectorAll("[data-color-box]");
    const defaultActiveId = switcher.getAttribute(["data-color-switcher"]);

    updateCheckedRadio(defaultActiveId);

    let activeId = defaultActiveId;
    function updateCheckedRadio(elementId) {
      if (activeId !== elementId) {
        colorButtons[activeId];
        colorButtons[activeId]?.removeAttribute("checked");

        activeId = elementId;

        colorButtons[activeId].setAttribute("checked", "");
      }
    }

    const boundedButton = document.querySelector(`[data-button-counter=${colorPicker.getAttribute("data-bounded-button")}]`);
    colorButtons.forEach((button, idx) => {
      button.addEventListener("click", () => {
        resetBoundedButton(boundedButton);
        updateCheckedRadio(idx);
      });
    });
  });
}

function resetBoundedButton(button) {
  button.classList.remove("active");
  button.removeAttribute("data-good-added");
  button.querySelector("[data-count]").value = 1;
}
