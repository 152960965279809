import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);


window.addEventListener('load', initStartSlider);
function initStartSlider() {
  const slidersParent = document.querySelectorAll('[data-progress-parent-slider]');
  slidersParent.forEach((sliderParent) => {
    const sliders = sliderParent.querySelectorAll('[data-progress-slider]');
    sliders.forEach((slider) => {
      if (slider.swiper) return false;
      
      const navigation = sliderParent.querySelector('[data-slider-navigation]') || null;
      const prevBtn = navigation.querySelector('.button-icon--prev') || null;
      const nextBtn = navigation.querySelector('.button-icon--next') || null;
      const pagination = navigation.querySelector('[data-slider-pagination]') || null;
      let activeIndex;
      const startSlider = new Swiper(slider, {
        modules: [Navigation, Pagination, Autoplay, EffectFade],
        slidesPerView: "auto",
        // autoplay: {
        //   delay: 10,
        // },
        speed: 1000,
        loop: false,
        // effect: 'fade',
        slidesPerGroup: 1,
				spaceBetween: 15,
        // followFinger: isMobile.matches,
        // shortSwipes: isMobile.matches,
        // longSwipes: isMobile.matches,
        // allowTouchMove: isMobile.matches,
        // resizeObserver: true,
        // watchOverflow: true,
  
        navigation: {
          prevEl: prevBtn,
          nextEl: nextBtn,
        },
        pagination: {
          el: pagination,
          type: 'fraction',
          formatFractionCurrent: addZero,
          formatFractionTotal: addZero
        },
      });
    });
    function addZero(num){
      return (num > 9) ? num : '0' + num;
    }
    
  });


}

