import intlTelInput from "intl-tel-input";
import { ru } from "intl-tel-input/i18n"; // Russian

let parentForm = document.querySelectorAll('[data-parent-countries-form]')
parentForm.forEach(element => {
	const input = element.querySelector("#phone");
	const button = element.querySelector('[data-faq-form-button-send]')
	const errorMsg = element.querySelector("#error-msg");
	const validMsg = element.querySelector("#valid-msg");
	const contactsFormParents = element.querySelector("[data-parent-countries-phone]");
	const errorMap = ["Неверный номер", "Неверный код страны", "Слишком короткий", "Слишком днинный", "Неверный номер"];
	button.disabled = true;
	const iti = intlTelInput(input, {
		// i18n: ru,
		// strictMode: true,
		countrySearch: false,
		containerClass: "parent-country",
		fixDropdownWidth: false,
		separateDialCode: true,
		dropdownContainer: contactsFormParents,
		// autoPlaceholder: 'aggressive',
		initialCountry: "ru",
		utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@24.4.0/build/js/utils.js" // just for formatting/placeholders etc
	
	});
	const reset = () => {
		console.log()
		input.classList.remove("error");
		errorMsg.innerHTML = "";
		errorMsg.classList.add("hide");
		validMsg.classList.add("hide");
	};
	
	const showError = (msg) => {
		input.classList.add("error");
		errorMsg.innerHTML = msg;
		errorMsg.classList.remove("hide");
	};
	
	// on click button: validate
	button.addEventListener('click', () => {
		reset();
		if (!input.value.trim()) {
			showError("Заполните это поле");
		} else if (iti.isValidNumber()) {
			validMsg.classList.remove("hide");
		} else {
			const errorCode = iti.getValidationError();
			const msg = errorMap[errorCode] || "Неверный номер";
			showError(msg);
			
		}
	});
	
	// on keyup / change flag: reset
	input.addEventListener('keyup', () => {
		if (!input.value.trim()) {
			console.log("1")
			showError("Заполните это поле");
		} else if (iti.isValidNumber()) {
			console.log("2")
			validMsg.classList.remove("hide");
			button.disabled = false;
		} else {
			console.log("3")
			
			const errorCode = iti.getValidationError();
			const msg = errorMap[errorCode] || "Неверный номер";
			input.classList.add("error");
			errorMsg.innerHTML = msg;
			errorMsg.classList.remove("hide");
			console.log(errorCode)
			console.log(msg)
			button.disabled = true;
		}
	});
	// input.addEventListener('change', reset);

	input.addEventListener('keyup', reset);



});

