import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
const defaultScrollTrigger = {
  start: 'top 10%',
  end: 'top 75%',
  toggleActions: 'play none none reverse',
};

class ScrollTitleVerticalAppear {
  constructor(container) {
    this.container = container;

    this.title = this.container.querySelector('[data-scroll-title-vertical]');
    this.text = this.container.querySelector('[data-scroll-text-vertical]');

    const titleHider = document.createElement('div');
    titleHider.className = 'title-vertical-hider';
    

    if(this.title) {
      
      this.title.appendChild(titleHider);
      gsap.set(this.title, {opacity: 0, autoAlpha: 1});
  
      gsap.to(this.title, {
        scrollTrigger: {
          ...defaultScrollTrigger,
          trigger: this.container,
        },
        opacity: 1,
        duration: 1.5
      });      
    }


    if (this.text) {
      gsap.to(this.text, {
        scrollTrigger: {
          ...defaultScrollTrigger,
          trigger: this.container,
        },
        opacity: 0,
        duration: 1.5
      });
    }
    gsap.to(titleHider, {
      scrollTrigger: {
        ...defaultScrollTrigger,
        trigger: this.container,
      },
      y: '120%',
      duration: 1.5
    });

    if (this.text) {
      gsap.to(this.text, {
        scrollTrigger: {
          ...defaultScrollTrigger,
          trigger: this.container,
        },
        autoAlpha: 1,
        duration: 1.5
      });
    }
  }
}

class ScrollTitleVerticalAppearController {
  constructor() {
    this.instances = [];
    if(isMobile == false) {
      document.querySelectorAll('[data-scroll-title-vertical-appear]')?.forEach((container) => {
        this.instances.push(new ScrollTitleVerticalAppear(container));
      });
    } else {
      return;
    }

  }
}

export default new ScrollTitleVerticalAppearController();
