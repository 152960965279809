const counterButtons = document.querySelectorAll("[data-button-counter]");
counterButtons.forEach((counterButton) => {
  let boundedSizesSelector = counterButton.getAttribute("data-button-counter");
  let boundedSizes = null;

  const sizeModal = document.querySelector("#modal-size");
  const basketModal = document.querySelector("#modal-basket");
  const sizeModalPicker = sizeModal.querySelector("[data-sizes-picker]");

  counterButton.addEventListener("click", () => {
    if (counterButton.getAttribute("data-good-added")) {
      return;
    }
    if (findSelectedSizes(boundedSizes)) {
      counterButton.classList.add("active");
      counterButton.setAttribute("data-good-added", true);

      const card = counterButton.closest("[data-card]");
      const cardData = {
        name: card.querySelector("[data-card-title]").getAttribute("data-card-title"),
        price: card.querySelector("[data-card-price]").getAttribute("data-card-price"),
        imagePath: card.querySelector("[data-image].active img").getAttribute("data-basket-image"),
        color: card.querySelector("[data-color-box][checked]").getAttribute("data-color-label"),
        size: card.querySelector("[data-sizes-picker]").SizePicker.currentValue,
      };
      updateBasketData(cardData);
    } else {
      sizeModal.modal.open();
      sizeModalPicker.setAttribute("data-bounded-sizepicker", counterButton.getAttribute("data-button-counter"));
      sizeModalPicker.querySelector(".active")?.classList.remove("active");
      sizeModalPicker.SizePicker.drawNewButtons(boundedSizes.SizePicker.sizes);
    }
  });

  if (boundedSizesSelector !== "") {
    boundedSizes = document.querySelector(`[data-sizes-picker=${counterButton.getAttribute("data-button-counter")}]`);
    initCounter();
  }

  
  function findSelectedSizes(node) {
    return node.querySelectorAll("[data-size].active").length;
  }

  function updateBasketData(cardDataObj) {
    const nameField = basketModal.querySelector("[data-modal-name] .modal__value");
    nameField.innerHTML = cardDataObj.name;

    const priceField = basketModal.querySelector("[data-modal-price] .modal__value");
    priceField.innerHTML = cardDataObj.price;

    const sizeField = basketModal.querySelector("[data-modal-size] .modal__value");
    sizeField.innerHTML = cardDataObj.size;

    const colorField = basketModal.querySelector("[data-modal-color] .modal__value");
    colorField.innerHTML = cardDataObj.color;

    const imageField = basketModal.querySelector("[data-modal-image]");
    imageField.setAttribute("src", cardDataObj.imagePath);

    basketModal.modal.open();
  }

  function initCounter() {
    const minusBtn = counterButton.querySelector("[data-minus]");
    const plusBtn = counterButton.querySelector("[data-plus]");
    const input = counterButton.querySelector("[data-count]");

    const minValue = input.getAttribute("min");
    const maxValue = input.getAttribute("max");
    minusBtn.addEventListener("click", () => {
      if (input.value !== minValue) {
        let newValue = +input.value - 1;
        input.value = newValue;
      }
    });
    plusBtn.addEventListener("click", () => {
      if (input.value !== maxValue) {
        let newValue = +input.value + 1;
        input.value = newValue;
      }
    });
  }
});
