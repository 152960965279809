const utils = {
  debounce(func, wait = 100, immediate = false) {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;

      const later = () => {
        timeout = null;
        !immediate && func.apply(context, args);
      };

      const callNow = immediate && !timeout;
      clearTimeout(timeout);

      timeout = setTimeout(later, wait);

      callNow && func.apply(context, args);
    };
  },

  throttle(func, wait = 100) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {
      if (isThrottled) {
        savedArgs = arguments;
        savedThis = this;
      } else {
        func.apply(this, arguments);

        isThrottled = true;

        setTimeout(() => {
          isThrottled = false;
          if (savedArgs) {
            wrapper.apply(savedThis, savedArgs);
            savedArgs = savedThis = null;
          }
        }, wait);
      }
    }

    return wrapper;
  }
};

export default utils;
