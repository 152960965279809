/* eslint-disable no-undef */
import ClassToggler from "../../../js/components/ClassToggler";
import { extend } from "../../../js/utils/objects";

export default class Menu extends ClassToggler {
  constructor($el, config = {}) {
    config = extend(
      {},
      defaults,
      Menu.defaults,
      window.menuDefaults || {},
      config,
      (() => {
        try {
          return JSON.parse($el.getAttribute("data-menu") || "{}");
        } catch (_) {
          console.error(_, $el);
          return {};
        }
      })()
    );

    super($el, config);

    this._zIndex = 10;

    this._isMouseDownTargetEl = false;
    this._isMouseUpTargetNotEl = false;
  }

  static initAll() {
    const $els = document.querySelectorAll(defaults.selectors.el);

    return [...$els].map(($el) => new Menu($el));
  }
}

const defaults = {
  closeOnDocumentClick: false,
  scrollLock: true,
  noId: true,

  classes: {
    active: "active",
  },

  selectors: {
    el: [".menu-section", ".menu-overlay", ".header"], // READONLY
    toggleBtns: ".j_toggleMenu",
    openBtns: ".j_openMenu",
    closeBtns: ".j_closeMenu",
    additionalEls: ".j_additionalEl",
  },
};

Menu.defaults = defaults;

Menu.initAll();
