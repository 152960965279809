import { gsap } from 'gsap';
let isMobile = window.matchMedia('(max-width: 1024px)').matches;
window.addEventListener('resize', function() {
  isMobile = window.matchMedia('(max-width: 1024px)').matches;
}, true);
class brandAppear {
  constructor(container) {
    this.container = container;

		this.brandText = this.container.querySelectorAll('.football-field__text');

		this.timeline = gsap.timeline({});	

		this.timeline.set(this.brandText, {autoAlpha: 0, y: '0%',});
		this.timeline.set(this.container, {autoAlpha: 1, y: '10%',});
		this.timeline.to(this.container, {
			autoAlpha: 1,
      duration: 1,
      y: '0%',
			// delay: 0.3,
    },"0");
    this.timeline.to(this.brandText, {
			autoAlpha: 1,
      duration: 1.8,
			delay: 0.3,
    },">");
		
  }

}

class brandAppearController {
  constructor() {
    this.instances = [];

		// this.onStart();
  }
	onStart() {
		if(isMobile == false) {
      const dataBrand =  document.querySelectorAll('[data-brand]')
      if(dataBrand.length) {
        dataBrand.forEach((container) => {
          this.instances.push(new brandAppear(container));
        });
      }

    } else {
      return;
    }
	}
}
new brandAppearController();

export default new brandAppearController();

